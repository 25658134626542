<template>
    <div class="home">
    <div class="container mx-auto ">
			<div class="flex justify-center px-6 my-12">
				<!-- Row -->
				<div class="w-full xl:w-3/4 lg:w-11/12 flex h-1/3">
					<!-- Col -->
					<div
						class="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg "
						style="background-image: url('https://source.unsplash.com/oWTW-jNGl9I/600x800')"
					></div>
					<!-- Col -->
					<div class="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
						<div class="px-8 mb-4 text-center">
                            <i  class="fa-lg"></i>
							<h3 class="pt-4 text-3xl mb-8">El paquete a sido entregado con exito!</h3>



						</div>
					</div>
				</div>
			</div>
		</div>
        
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from 'axios'
export default {
    name: 'HomeView',
    layout: 'reparto',
}
</script>
